@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-[#F5F6FB] text-sm text-[#2C3568];
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
    @apply w-[375px] lg:w-[800px] m-auto transition-all ease-in-out duration-200 p-7;
}

.header {
    @apply h-[57px] mb-1 bg-white rounded-b-3xl flex justify-center items-center;
}

.cv-input {
    @apply h-[42px] py-3 px-3.5 text-xs rounded-lg bg-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75;
    box-shadow: 0px 3px 6px #00000029;;
}

.cv-input:focus {
    @apply border-2 border-[#444293];
}

.cv-input-invalid {
    @apply border-red-600 border;
}

.btn {
    @apply bg-[#444293] h-[42px] w-full text-white rounded-lg text-sm font-bold disabled:bg-[#A8A8A8];
}

.btn.btn-outline {
    @apply bg-white border-2 border-primary text-primary;
}


/* CHECKBOX INPUT */
.check {
    @apply inline-block relative w-[22px] h-[22px] mr-2.5 my-2.5 bg-transparent rounded-xl ;
    box-shadow: 0 0 0 0 #c4cbe8;
    transition: box-shadow 100ms ease-in;
}
.check:hover > .check__input:not([disabled]){
    box-shadow: 0 0 0 10px #c4cbe8;
    @apply cursor-pointer
}
.check__input:focus {
    outline: none;
}
.check__input {
    @apply absolute w-[1.25rem] h-[1.25rem] z-10 top-0 left-0 opacity-0 border;
}
.check__input:disabled {
    @apply absolute w-[1.25rem] h-[1.25rem] z-10 top-0 left-0 opacity-0 border cursor-not-allowed bg-white;
}
.check__box {
    @apply absolute w-0 h-0 p-[0.625rem] top-0 left-0 rounded-md bg-white bg-no-repeat block;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    box-shadow: 0px 3px 6px #00000029;
}
.check .check__input:focus + .check__box {
    @apply border-accent;
    -webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}
.check .check__input:disabled + .check__box {
    @apply cursor-not-allowed border-gray-400 bg-gray-200;
}
.check .check__input:checked + .check__box {
    @apply bg-center bg-no-repeat bg-[#444293] bg-[url("./assets/svg/check.svg")];
}


/* RADIO INPUT */
.radio {
    @apply inline-block relative w-[22px] h-[22px] mr-2.5 my-2.5 bg-transparent rounded-xl ;
    box-shadow: 0 0 0 0 #c4cbe8;
    transition: box-shadow 100ms ease-in;
}
.radio:hover > .radio__input:not([disabled]){
    box-shadow: 0 0 0 10px #c4cbe8;
    @apply cursor-pointer
}
.radio__input:focus {
    outline: none;
}
.radio__input {
    @apply absolute w-[1.25rem] h-[1.25rem] z-10 top-0 left-0 opacity-0 border;
}
.radio__input:disabled {
    @apply absolute w-[1.25rem] h-[1.25rem] z-10 top-0 left-0 opacity-0 border cursor-not-allowed bg-white;
}
.radio__box {
    @apply absolute w-0 h-0 p-[0.625rem] top-0 left-0 rounded-full bg-white bg-no-repeat block;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    box-shadow: 0px 3px 6px #00000029;
}
.radio .radio__input:focus + .radio__box {
    @apply border-accent;
    -webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}
.radio .radio__input:disabled + .radio__box {
    @apply cursor-not-allowed border-gray-400 bg-gray-200;
}
.radio .radio__input:checked + .radio__box {
    background: radial-gradient(circle at center, white 0, white 28%, #444293 28%, #444293 100%);
}

.header-quiz {
    @apply m-auto;
}

.wrapper-quiz {
    background: transparent linear-gradient(209deg, #4E4BCE 0%, #343367 100%) 0% 0% no-repeat padding-box;
    @apply min-h-screen h-max relative p-8 w-screen overflow-hidden
}

.answer {
    @apply py-2 px-3.5 bg-white rounded-lg text-base font-semibold text-left border-[3px] border-white
}

.answer.selected-answer {
    @apply border-[#E4087E];
}

.counter-bg {
    background: radial-gradient(circle at center, white 0, white 66%, transparent 66%, transparent 100%)
}